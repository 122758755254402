.container,
.container-fluid {
  /*outline: dotted 1px blue;*/
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl,
.col-xxl-auto {
  /*outline: dotted 1px black;*/
}

:root {
  --primary-color: black;
  --secondory-color: black;
  --navbg-color: black;
  --nav-text-color: #fff;
  --nav-active-text-color: #ffc107;
  --submenu-bgcolor: #fff;
  --submenu-text-color: #000;
  --submenu-active-bgcolor: #fff;
  --submenu-active-text-color: #252525;
  --footerbg-color: black;
  --footer-text-color: white;
  --footer-logo-color: white;
  --btn-cart-color: black;
  --logo-header-bgcolor: #fff;
  --logo-header-logotext-color: #000;
  --chooser-services-bg-color: #f8f9fa;
  --chooser-services-text-color: #212529;
  --btn-findfood-bgcolor: black;

  --font-pico: 9px;
  --font-nano: 10px;
  --font-micro: 11px;
  --font-uxs: 13px;
  --font-xs: 14px;
  --font-sm: 16px;
  --font-md: 18px;
  --font-lg: 20px;
  --font-xl: 22px;
  --font-2xl: 24px;
  --font-3xl: 26px;
  --font-4xl: 28px;
  --font-5xl: 30px;
  --font-6xl: 32px;
  --font-footer: 16px;
}

/* MD */
@media (min-width: 768px) {
  :root {
    --font-pico: 10px;
    --font-nano: 11px;
    --font-micro: 12px;
    --font-uxs: 13px;
    --font-xs: 15px;
    --font-sm: 17px;
    --font-md: 19px;
    --font-lg: 21px;
    --font-xl: 23px;
    --font-2xl: 25px;
    --font-3xl: 27px;
    --font-4xl: 29px;
    --font-5xl: 31px;
    --font-6xl: 33px;
    --font-footer: 16px;
  }
}

/* LG */
@media (min-width: 992px) {
  :root {
    --font-pico: 11px;
    --font-nano: 12px;
    --font-micro: 13px;
    --font-uxs: 14px;
    --font-xs: 16px;
    --font-sm: 18px;
    --font-md: 20px;
    --font-lg: 22px;
    --font-xl: 24px;
    --font-2xl: 26px;
    --font-3xl: 28px;
    --font-4xl: 30px;
    --font-5xl: 32px;
    --font-6xl: 34px;
    --font-footer: 16px;
  }
}

/* XL */
@media (min-width: 1200px) {
  :root {
    --font-pico: 12px;
    --font-nano: 13px;
    --font-micro: 14px;
    --font-uxs: 15px;
    --font-xs: 17px;
    --font-sm: 18px;
    --font-md: 21px;
    --font-lg: 23px;
    --font-xl: 25px;
    --font-2xl: 27px;
    --font-3xl: 29px;
    --font-4xl: 31px;
    --font-5xl: 33px;
    --font-6xl: 35px;
    --font-footer: 17px;
  }
}

/* XXL */
@media (min-width: 1400px) {
  :root {
    --font-pico: 13px;
    --font-nano: 14px;
    --font-micro: 15px;
    --font-uxs: 15px;
    --font-xs: 18px;
    --font-sm: 20px;
    --font-md: 22px;
    --font-lg: 24px;
    --font-xl: 26px;
    --font-2xl: 28px;
    --font-3xl: 30px;
    --font-4xl: 32px;
    --font-5xl: 34px;
    --font-6xl: 36px;
    --font-footer: 17px;
  }
}

/* Greater Than XXL */
@media (min-width: 1600px) {
  :root {}
}

@font-face {
  font-family: "latosemibold";
  src: url("./fonts/lato-semibold-webfont.woff2") format("woff2"),
    url("./fonts/lato-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "latoheavy";
  src: url("./fonts/lato-heavy-webfont.woff2") format("woff2"),
    url("./fonts/lato-heavy-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  height: 100%;
}

body {
  background-color: #f5f5f5;
  color: #000;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

.btn-theme-primary {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.btn-theme-primary-outline {
  color: var(--primary-color) !important;
  background-color: #fff !important;
  border: 2px solid var(--primary-color);
  padding: 1px;
}

.btn-theme-primary-outline:hover {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.app-container {
  max-width: 1240px;
  margin: 0 auto;
  /* padding: 0px; */
}

.app-header {
  box-shadow: 0 3px 25px rgba(0, 0, 0, 0.16);
  z-index: 1;
  position: relative;
  padding: 0;
  background-color: var(--logo-header-bgcolor);
}

.app-header-row {
  justify-content: space-between;
  align-items: center;
}

.logo-img-box {
  /* padding: 6px 10px 6px 0 !important; */
  /*P*/
  height: 100px;
  /*Old Value: 110px */
  /* max-height: 100%; */
  /*P*/
  align-items: center;
  display: flex;
  cursor: pointer;
  /* border: solid 2px red; */
  padding-left: 8px;
}

.logo-img-box img,
.footer-logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  /* contain-width & cover-height */
  object-position: left center;
  /* border: solid 2px blue; */
}

.logo-img-box img {
  object-position: left center;
}

.loader-color {
  color: "#ffc107";
}

.img-logo {
  /* max-height: 100%;
  object-fit: contain;
  max-width: 100%; */
  /*P*/
}

.search-container {
  /*max-width: 480px;*/
  /*P*/
  /* margin: auto; */
  /*P*/
}

.search-container {
  margin-left: 0px;
  /* margin-top: 5px; */
  padding: 8px;
  /* border-top: 1px solid #fff; */
}

.search-textbox {
  height: 44px;
  border-color: #b3b3b3;
  border-radius: 36px;
}

.btn-search {
  background-color: var(--btn-findfood-bgcolor) !important;
  color: #fff !important;
  position: absolute !important;
  top: 0;
  right: 0;
  height: 44px;
  box-shadow: none;
  font-weight: 600;
  letter-spacing: 0.22px;
  text-transform: uppercase;
  padding: 0 15px !important;
  z-index: 6 !important;
  font-size: 14px !important;
  border-color: var(--btn-findfood-bgcolor) !important;
}

.btn-acc-header {
  box-shadow: none;
  border: 0 none;
  height: 40px;
  /*Old value: 46px*/
  /*P*/
  line-height: 40px;
  /*Old value: 44px*/
  /*P*/
  border-radius: 35px;
  font-size: var(--font-uxs);
  /*Old value: 16px*/
  /*P*/
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  padding: 0 23px;
  text-align: center;
  -webkit-appearance: none;
  transition: opacity 0.2s ease;
  text-decoration: none;
}

.btn-acc-header.btn-signup {
  background-color: var(--secondory-color);
  color: #fff;
}

.btn-acc-header.btn-login {
  background-color: var(--primary-color);
  color: #fff;
}

.btn-proceed-checkout {
  min-height: 60px;
  line-height: 60px;
  font-size: 16px;
  font-weight: 900;
}

.cart-noitem-msg {
  font-size: 13px;
  color: red;
  text-transform: capitalize;
}

.app-menubar {
  background-color: var(--navbg-color);
  padding: 0;
}

.app-menubar .navbar-nav {
  flex-wrap: wrap !important;
}

.menu-type {
  background: var(--nav-text-color) !important;
  /* border-top: 4px solid var(--nav-active-text-color); */
  border-top: transparent;
  min-width: 120px;
  text-align: center;
  padding-left: 15px;
}

.menu-type .dropdown-toggle::after {
  border: 0 !important;
  content: "" !important;
}

.menu-type .nav-link {
  color: var(--nav-active-text-color) !important;
  font-weight: 800 !important;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  font-weight: 900 !important;
}

.menu-type::before {
  position: relative;
  margin-right: 5px;
  top: 0;
  content: "\f0c9";
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  color: var(--nav-active-text-color);
  font-size: 16px;
}

.menu-item {
  position: relative;
  margin-right: 15px;
  color: var(--nav-text-color) !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 2.5;
  text-decoration: none;
  width: auto;
  float: right;
  display: flex;
  align-items: center;
}

.menu-item:not(.menu-type) .nav-link {
  color: var(--nav-text-color) !important;
  padding: 0;
}

.menu-item.active,
.menu-item.active .dropdown-toggle.nav-link {
  color: var(--nav-active-text-color) !important;
  font-weight: 800 !important;
  width: auto;
  float: right;
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
  color: var(--bs-navbar-active-color);
}

.menu-item:not(.menu-type).active:after,
.menu-item:not(.menu-type):hover:after {
  background: var(--nav-active-text-color);
  bottom: 0;
  position: absolute;
  content: "";
  height: 3px;
  width: 100%;
  left: 0;
  transition: width 0.2s linear;
  right: 0;
}

.choose-service-section {
  background-color: var(--chooser-services-bg-color);
  /* min-height: 70px; */
  /* padding: 16px; */
  /* max-width: 100%; */
}

.choose-service-text {
  color: var(--chooser-services-text-color) !important;
}

.choose-title {
  font-size: var(--font-sm);
  /*Old value: 1.2rem*/
  /*P*/
  font-weight: 900;
  text-transform: uppercase;
  max-width: 230px;
}

.btn-restart {
  background: transparent;
  border: 1px solid var(--chooser-services-text-color);
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--chooser-services-text-color);
  font-size: var(--font-nano);
  /* Old value 0.8rem */
}

.btn-restart:hover {
  background: transparent;
  color: var(--chooser-services-text-color);
}

.mh-70 {
  min-height: 70px;
}

.service-radios {
  /* border: solid 2px red; */
  display: flex;
  flex-direction: row;
}

.service-radios .form-check-input {
  width: calc(var(--font-xs) * 1.5);
  /* 27px */
  height: calc(var(--font-xs) * 1.5);
  cursor: pointer;
}

.service-radios .form-check-label {
  flex: 1;
  /* line-height: 35px; */
  /* padding: 0px 0px 1px 6px; */
  /* display: inline-block; */
  /* position: relative; */
  /* font-size: 16px; */
  padding: 4px 0px 0px 6px;
  font-size: var(--font-xs);
  /* line-height: calc(var(--font-uxs) * 2.1); */
  font-weight: 700;
  margin: 0;
  cursor: pointer;
  text-transform: uppercase;
}

.cart-icon {
  width: 30px;
  height: 30px;
  /* margin-right: 26px; */
  cursor: pointer;
}

.cart-icon:hover {
  color: var(--primary-color);
}

.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.menu-item-title {
  font-family: "latosemibold";
  font-weight: normal;
  font-size: var(--font-2xl);
  /* line-height: 36px; */
  /* margin: 18px 0 0; */
  text-transform: uppercase;
  padding: 8px;
}

.dish-title-sep {
  /* margin-top: 12px; */
  height: 2px;
  background: #f0f0f0;
  /* margin-bottom: 30px; */
}

.i-menu-item-header {
  color: var(--navbg-color);
  position: relative;
  bottom: 3px;
  left: 8px;
}

.list-row {
  flex-wrap: wrap;
  margin: 0 -12.5px;
}

.item-box {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 12.5px;
  margin-bottom: 30px;
}

.item-box-inner {
  position: relative;
  /* border: solid 1px blue; */
  border-radius: 2px;
  margin: 0;
  padding: 0;
}

.item-box-inner figure {
  position: relative;
  margin: 0;
  overflow: hidden;
}

.item-box-inner figure .img-org {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  margin: auto;
}

img.img-placeholder {
  width: 100%;
  height: auto;
}

.item-box-card {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  height: 100%;
  border: 0;
  border-radius: 10px;
  overflow: hidden;
}

.item-header,
.item-footer {
  border: 0;
  background-color: transparent;
  padding: 0px var(--font-pico);
}

.item-box-inner {
  display: flex;
  flex-direction: column;
}

.item-box-inner:hover {
  /* transform: translateY(-6px); */
}

.promotion-price {
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--secondory-color);
  color: #fff;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 900;
  padding: 6px 6px 5px 7px;
  font-size: 14px;
}

.item-detail {
  color: #000000;
  font-family: "latosemibold";
  font-weight: normal;
  font-size: var(--font-xs);
  line-height: var(--font-xs);
  /* padding: 20px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--font-pico);
}

.item-tags-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.item-tags-box .tag-badge {
  font-weight: 400;
  font-size: 8px;
  border: 1px solid #d5d5d5;
  text-transform: uppercase;
  margin: 0px 2px 2px 0px;
}

.item-qty {
  font-size: 14px;
  line-height: 18px;
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  font-family: "Lato", sans-serif;
  margin-bottom: var(--font-uxs);
}

.item-qty p {
  margin-bottom: 0px;
}

.item-price-cart {
  justify-content: space-between;
  align-items: center;
  /* margin-top: auto; */
}

.item-price {
  color: #000;
  font-weight: 600;
  font-family: "Lato", sans-serif;
  font-size: var(--font-uxs);
  /* border: solid 1px red; */
  display: flex;
  align-items: center;
}

.item-price .product-detail-strike-box {
  padding-right: 4px;
}

.item-price .old-price {
  color: #000;
  font-size: 16px;
  text-decoration: line-through;
  margin-right: 5px;
}

.item-cart-btn {
  border-radius: 20px;
  min-height: 40px;
  background-color: transparent;
  border: 1px solid var(--btn-cart-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  font-size: 16px;
  color: var(--btn-cart-color);
  padding: 0 17px;
}

.item-cart-btn svg {
  fill: var(--btn-cart-color);
}

.item-cart-btn svg {
  margin-right: 6px;
}

.item-cart-btn:hover,
.item-cart-btn:focus {
  border-color: var(--btn-cart-color);
  color: var(--btn-cart-color);
}

.item-cart-btn:hover svg,
.item-cart-btn:focus svg {
  fill: var(--btn-cart-color);
}

.item-notavailabletime {
  color: #f00;
  font-size: 12px;
  margin: 0;
}

.site-footer {
  background-color: var(--footerbg-color);
  color: var(--footer-text-color);
  padding: 60px 15px;
  margin-top: 50px;
}

.footer-address address {
  margin: 0;
  font-family: "latosemibold";
  font-size: var(--font-footer);
  line-height: 32px;
}

.address-title {
  color: var(--footer-logo-color);
  font-family: "latoheavy";
  font-size: var(--font-sm);
  font-weight: normal;
  line-height: 32px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.footer-bottom {
  border-top: 1px solid var(--primary-color);
  font-size: var(--font-footer);
  font-family: "latosemibold";
  padding-top: 32px;
}

.contact-detail {
  color: #fcfcfc;
  font-family: "Open Sans", sans-serif;
  font-size: var(--font-sm);
  font-weight: 700;
  display: flex;
  margin-bottom: 10px;
  line-height: 33px;
  justify-content: end;
}

.footer-logo {
  font-size: 42px;
  line-height: 71px;
  font-weight: 700;
  color: var(--footer-logo-color);
  text-transform: uppercase;
  padding: 6px 10px 6px 0 !important;
  height: 110px;
  max-height: 100%;
  align-items: center;
  display: flex;
  cursor: pointer;
  justify-content: end;
}

.contact-detail,
.footer-logo {
  text-align: end;
}

.contact-detail img {
  margin-left: auto;
}

.text-footer {
  text-decoration: none;
}

.text-footer,
.text-footer:hover {
  color: var(--footer-text-color);
}

.contact-detail img {
  margin-right: 19px;
}

.slider-adv {
  height: 85px;
  margin-top: 10px;
  position: relative;
  margin-bottom: 95px;
}

.adv-img {
  margin-left: auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  margin-right: auto;
  height: 165px;
  width: 100%;
}

.slider-adv .slick-list {
  overflow-y: visible !important;
}

.slick-prev:before,
.slick-next:before {
  color: var(--primary-color);
}

.slider-adv-slider .slick-slide[aria-hidden] {
  line-height: 0;
}

.adv-info-box-main {
  background: rgb(0, 0, 0);
  border-radius: 20px;
  overflow: hidden;
  padding-bottom: 0 !important;
  min-height: 294px;
  display: flex;
  align-items: center;
  padding: 0 80px;
  margin-top: 5px !important;
}

.adv-info-box {
  background-size: 180% 180%;
  animation: gradient-animation 9s ease infinite;
  color: #fff;
  line-height: 1.8;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  text-align: center;
  min-height: 199px;
  padding: 0 15px;
}

.adv-header {
  font-size: 3.2em;
}

.adv-desc {
  font-size: 1rem;
}

.slick-track {
  display: flex !important;
  align-items: center;
}

@keyframes adv-info-box {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.slider-adv .slick-slide {
  padding-right: 3px;
  padding-left: 3px;
}

.modal-title {
  font-size: var(--font-lg);
  /* Old value 24px */
  font-weight: 900;
  text-transform: uppercase;
  /*padding-left: 8px;
  padding-right: 60px;
  line-height: 29px;
  padding-top: 8px;
  padding-bottom: 8px;*/
  margin: 0;
}

.modal-header .btn-close {
  position: absolute;
  font-size: 22px;
  right: 30px;
  /* height: 21px; */
  /* width: 21px; */
  /* top: 33px; */
  cursor: pointer;
  border-radius: 0;
  border: 0 none;
  box-shadow: none;
  padding: 0;
  transition: all 0.2s;
  color: #000;
}

.delivery-option {
  background-color: #f2f0f0;
  min-height: calc(var(--font-uxs) * 4.5);
  /* Old value 66px */
  display: flex;
  justify-content: space-between;
}

.delivery-btn {
  background-color: transparent;
  color: #000;
  border: 0 none;
  text-align: center;
  flex: 1 1 auto;
  padding: 8px 8px;
  /* Old value 0px */
  font-weight: 900;
  font-size: var(--font-uxs);
  /* Old value 16px */
  text-transform: uppercase;
  box-shadow: none;
  outline: 0;
  transition: all 0.2s ease;
  width: 33.33333333%;
  border-radius: 0;
}

.delivery-btn.active {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

.delivery-btn:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-color: transparent;
}

.popup-sm-text {
  padding-bottom: 11px;
  margin: 0;
  font-size: 16px;
  line-height: 19px;
}

.form-input {
  /*height: 66px; */
  /* Old value 66px */
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #000;
  font-size: var(--font-xs);
  /* Old value 18px */
  /* line-height: 22px; */
  font-family: "latosemibold";
  padding: 10px 60px 10px 10px;
  /* Old value 18px 60px 18px 22px */
  border-radius: 0;
}

.form-input-bg {
  /* background-color: #f2f0f0; */
}

.form-input-icon {
  position: absolute;
  right: 22px;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
  width: 22px;
  justify-content: center;
}

.when-title {
  font-size: var(--font-sm);
  /* Old value 18px */
  /* line-height: 22px; */
  font-weight: 700;
  margin-bottom: 21px;
  text-align: center;
  width: 100%;
}

.when-pic {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  text-align: center;
  max-width: 280px;
  margin: 0 auto;
  padding-bottom: 20px;
}

.when-pic-inner {
  display: flex;
  justify-content: space-between;
}

.when-pic .sym-round {
  height: 70px;
  width: 70px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #bbbbbc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 11px;
  transition: all 0.2s ease;
}

.when-symbol {
  transition: all 0.2s ease;
}

.when-symbol:hover {
  color: var(--primary-color);
}

.when-symbol:hover .sym-round {
  border-color: var(--primary-color);
}

.when-symbol:hover .sym-round svg {
  fill: var(--primary-color);
}

.when-symbol.active {
  color: var(--primary-color);
}

.when-symbol.active .sym-round {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}

.when-symbol.active .sym-round svg {
  fill: #fff;
}

.when-pic .sep-link {
  flex: 1 1;
  /* border-top: 1px solid #BBBBBC; */
  align-self: center;
  margin-top: -32px;
}

.done-btn {
  border: 0 none;
  display: block;
  background-color: #000;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  color: #fff;
  /* height: 66px; */
  width: 100%;
  text-transform: uppercase;
  font-size: var(--font-sm);
  /* 20px */
  padding: 10px;
  font-weight: 900;
  letter-spacing: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-notify-serivce-info {
  position: relative;
  bottom: 2px;
  right: 1px;
  font-size: 16px;
  color: var(--primary-color);
}

.service-acc-item {
  margin-bottom: 20px;
  border: 0;
}

.service-acc-header {
  margin-bottom: 8px;
  border-radius: 0 !important;
}

.service-acc-header .accordion-button {
  /* height: 66px; */
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #000;
  font-size: 18px;
  line-height: 22px;
  font-family: "latosemibold";
  padding: 10px 22px 10px 22px;
  color: #000;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  background: #fff;
  border-radius: 0 !important;
}

.service-acc-icon {
  position: absolute;
  right: 22px;
  font-size: 24px;
}

.service-acc-header .accordion-button::after {
  display: none;
}

.service-slot-body {
  border: 1px solid #000;
  max-height: 190px;
  overflow: auto;
}

.service-slot-row {
  border: transparent;
  border-bottom: 1px solid #eee;
}

.service-slot-table tr td {
  color: #000;
  font-weight: 600;
  font-size: 18px;
  padding-left: 22px;
  cursor: pointer;
}

.service-slot-row:hover {
  background-color: var(--primary-color);
}

.service-slot-row:hover td {
  color: #fff;
}

.pick-address {
  border: 2px solid var(--primary-color);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  letter-spacing: 0.28px;
  font-family: "latosemibold";
  font-size: 18px;
  line-height: 22px;
  padding: 10px;
  /* Old value: 20px 24px; */
  cursor: pointer;
  margin-bottom: 15px;
}

.btn-use-address {
  background-color: var(--btn-findfood-bgcolor);
  color: #fff;
  box-shadow: none;
  font-weight: 900;
  letter-spacing: 0.22px;
  font-size: 12px !important;
  text-decoration: none !important;
  cursor: pointer;
}

.delivery-address-title {
  display: flex;
  justify-content: space-between;
}

.selected-del-address-body {
  border: 1px solid #14161914;
  max-height: 190px;
  overflow: auto;
}

.selected-del-address {
  color: #2196f3;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
  max-height: 190px;
  overflow: auto;
}

.selected-del-address svg {
  color: #b5b5b5;
  font-size: 16px;
}

.food-dtl-img {
  text-align: center;
  position: relative;
  overflow: hidden;
  height: 225px;
  margin: 0;
}

.food-dtl-img img:not(.img-placeholder) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  margin: auto;
  max-height: 100%;
  /* object-fit: contain; */
  object-fit: cover;
  margin-top: 0;
}

.food-name-price {
  padding: 0px 10px;
  align-items: baseline;
  border-bottom: 2px solid #fcfcfc;
  margin-bottom: 20px;
}

.foodname {
  font-family: "latosemibold";
  font-size: 14px;
  line-height: 14px;
  flex: 0 0 calc(100% - 120px);
  padding: 10px 0;
}

.foodname span {
  color: var(--primary-color);
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: 21px;
  font-weight: 900;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0px;
}

.pd-addon-card {
  padding-bottom: 5px;
}

.foodprice {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 900;
}

.item-detail-acc-item {
  border-radius: 0 !important;
  border: 0 !important;
}

.item-details-acc-heading .accordion-button {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding: 8px 12px 8px 20px;
  font-size: 12px;
  border-color: transparent;
  /* background-color: #f2f0f0; */
  background-color: #03a9f403;
  color: #000;
  font-weight: 500;
  outline: none;
  border-radius: 0 !important;
  position: relative;
  border: 0;
  background: #f4f4f4;
  box-shadow: none !important;
}

.item-details-acc-heading .accordion-button::after {
  position: absolute;
  right: 20px;
  top: calc(50% - var(--font-xs)/2);
  content: "\2b";
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  background-image: none !important;
  font-size: var(--font-xs);
  transform: none;
}

.item-details-acc-heading .accordion-button[aria-expanded="true"]::after {
  content: "\f068" !important;
}

.item-details-acc-heading .accordion-button:focus {
  box-shadow: none;
}

.id-acc-hd-row {
  margin-right: auto;
  font-family: "latosemibold";
  font-size: 12px;
  line-height: 17px;
}

.id-addon-name {
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: 900;
  display: block;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.required-info {}

.required-info-hihglight {
  color: #ff0017;
  box-shadow: 1px 2px 2px 1px #cbb;
  padding: 1px 5px;
  border-radius: 2px;
  font-size: 11px;
  border: 1px solid #ff0017;
  background: #fff;
  max-width: 150px;
  text-align: left;
  width: 100%;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}

.max-w-160 {
  max-width: 160px;
}

.min-w-210 {
  /* min-width: 210px; */
}

.item-det-add-to-cart {
  background-color: var(--primary-color);
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  border: 0 none;
  font-weight: 900;
  font-size: var(--font-micro);
  /* Old value 14px */
}

.item-det-qty-ctrl {
  background-color: var(--primary-color);
  color: #fff;
  padding: 8px;
  line-height: 1;
  font-size: 20px;
}

/* .item-dtl-cart-ctrls {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  max-width: 100%;
  justify-content: space-between;
} */

.btn-variant {
  width: 140px;
  float: left;
  max-width: 140px;
  margin: 8px 8px 8px 0;
  font-size: 13px;
}

.add-on-row {
  /* border-bottom: 1px solid #eee; */
}

.add-on-name {
  /* padding-left: 4px; */
  font-size: var(--font-micro);
}

.add-on-price {
  padding-left: 8px;
  font-size: var(--font-pico);
  color: #666;
}

.add-on-name,
.add-on-price {
  padding-left: 4px;
  font-weight: 600;

  text-transform: capitalize;
}

.add-on-price {
  text-align: right;
}

.add-on-switch {
  text-align: right;
  max-width: 80px;
  width: 60px;
  font-size: var(--font-pico);
}

.add-on-checkbox {
  font-size: var(--font-micro);
  text-align: left;
  line-height: calc(var(--font-micro) * 2);
}

.add-on-checkbox div {
  padding-right: 0 !important;
}

.add-on-checkbox .form-check-input,
.pd-combo-item-chk .form-check-input {
  height: calc(var(--font-micro) * 1.4);
  width: calc(var(--font-micro) * 1.4);
}

.pd-modal {
  max-width: 576px;

}

.modal-dialog {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1) !important;
}

.modal-content {
  border-radius: 10px;
}

.cart-popover {
  background-color: #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  min-width: 400px;
  max-width: 400px;
  margin-top: 35px;
  z-index: 1501;
  border-radius: 0;
  border: 0;
}

.cart-popover-header {
  padding: 15px;
  background-color: #fff;
  color: #000;
  text-transform: uppercase;
  border-radius: 0;
  font-weight: 600;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #b6b6b6;
  font-size: var(--font-sm);
}

.cart-popover-header::before {
  border: 0 !important;
}

.cart-popover .popover-arrow {
  display: none;
}

.cart-popover-body {
  padding: 0;
  border-radius: 0;
}

.cart-items-container {
  padding-top: 2px;
  padding-bottom: 2px;
}

.tbl-cart-service-info tr {
  border-bottom: 1px dashed #f8f8f8;
}

.tbl-cart-service-info tr:last-of-type {
  border-bottom: 0;
}

.tbl-cart-service-info tr td {
  /* padding: 5px 8px; */
}

.si-col-title {
  width: 50%;
  font-size: 14px;
  font-weight: 700;
}

.si-col-val {
  font-weight: 600;
  color: #000;
  width: 50%;
  text-align: right;
  font-size: 14px;
}

.cart-service-i {
  color: #0d6efd;
}

.tbl-cart-item-info tr td {
  padding: 0px 5px;
}

.tbl-cart-item-info tr {
  border-bottom: 1px solid #eee;
}

.i-del-cart-item {
  padding: 0 !important;
  font-size: 20px;
  color: #000;
  cursor: pointer;
}

.cart-items-container {
  padding: 0;
}

.cart-items-container {
  max-height: 370px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: red #cfd8dc;
  overflow: auto;
}

.cart-items-container::-webkit-scrollbar {
  width: 8px;
}

.cart-items-container::-webkit-scrollbar-track {
  background: #fff;
}

.cart-items-container::-webkit-scrollbar-thumb {
  background-color: red;
  border-radius: 6px;
  border: 3px solid #fff;
}

.cart-item-row {
  margin: 0;
  border-top: 1px solid #b6b6b6;
  border-bottom: 1px solid #b6b6b6;
  font-size: 18px;
  line-height: 17px;
  padding: 16px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.cart-item-row:nth-of-type(odd) {
  background: #fff;
}

.cart-item-row:nth-of-type(even) {
  background: #fff;
}

.cart-item-name {
  font-weight: 600;
  font-size: var(--font-sm);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.order-item-name {
  font-size: 16px;
}

.cart-item-desc {
  font-size: var(--font-uxs);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #7b7b7b;
  padding-left: 8px;
}

.prod-addon-container {
  padding: 8px 0;
}

.order-item-desc {
  font-size: 14px;
}

.cart-img-box {
  width: 100%;
  max-height: 60px;
  padding: 0 5px;
  max-width: 90px;
  display: none;
}

.cart-img {
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  max-height: 60px;
  border: 1px solid #eee;
}

.cart-item-price {
  font-weight: 700;
  color: #000;
  font-size: 12px;
}

.cart-input-ctrl {
  padding: 5px;
  font-size: 12px;
  height: 22px;
  border-radius: 0;
  background: #525252;
  color: #fff;
}

.cart-qty-input {
  font-size: 12px;
  height: 22px;
  border-radius: 0;
  font-weight: 600;
}

.card-edit {
  color: #0d6efd;
  font-size: 12px;
  font-weight: 700;
  margin-right: 8px;
  cursor: pointer;
  padding-right: 8px;
  border-right: 2px solid #f5f5f5;
}

.card-delete {
  color: var(--primary-color);
  font-size: 12px;
  font-weight: 700;
  margin-right: 5px;
  cursor: pointer;
  display: none;
}

.cart-item-qty-box {
  max-width: 90px;
  display: flex;
  align-items: center;
}

.cart-price-box {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 65px;
}

.cart-addedcount {
  background-color: var(--primary-color);
  height: 18px;
  width: 18px;
  border-radius: 50%;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 9px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -8px;
  right: -5px;
}

.cart-charge-info {
  font-family: "Lato", sans-serif;
  font-size: var(--font-pico);
  font-weight: 400;
  display: block;
  margin: 0;
  padding: 0 !important;
}

.checkout-qty {
  font-weight: 600;
  font-size: var(--font-sm);
  text-transform: uppercase;
}

.od-qty {
  font-weight: 700;
  font-size: var(--font-pico);
  text-transform: uppercase;
}

.cart-item-price {
  font-weight: 600;
  color: #000;
  font-size: var(--font-sm);
}

.od-item-name {
  font-weight: 600;
  font-size: var(--font-nano);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-left: 8px;
}

.cart-delete-i {
  font-size: var(--font-xl);
  cursor: pointer;
}

.tbl-cart-charge-info tr {
  border-bottom: 1px solid #fdf8f8;
}

.tbl-cart-charge-info tr:last-of-type {
  border-bottom: 0;
}

.tbl-cart-charge-info tr td {
  padding: 5px 8px 5px 0;
}

.ci-col-title {
  font-weight: 700;
  width: 50%;
  font-size: 14px;
}

.ci-col-val {
  font-weight: 700;
  color: #000;
  width: 50%;
  text-align: right;
  font-size: 14px;
}

.cart-popuover-open {
  /* opacity: 0.1; */
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  background-color: #fff;
  /* overflow: hidden; */
}

.cart-close-popover {
  font-size: 20px;
  cursor: pointer;
}

.menu-item .dropdown-menu {
  margin: 0;
  line-height: 1;
  border: 1px solid #eee;
  font-size: 12px;
  visibility: visible;
  opacity: 1;
  padding: 0;
  background-color: var(--submenu-bgcolor) !important;
  left: 0;
  min-width: 150px;
  border-radius: 3px;
  box-shadow: 0 3px 25px rgba(0, 0, 0, 0.16);
  transition: all 0.2s ease;
  z-index: 1;
}

.menu-item .dropdown-menu .dropdown-item {
  padding: 8px 8px;
  font-size: 14px;
  border-bottom: 1px solid #eee;
  font-weight: 600;
  color: var(--submenu-text-color);
}

.menu-item .dropdown-menu .dropdown-item.active,
.menu-item .dropdown-menu .dropdown-item:active {
  background-color: var(--submenu-active-bgcolor) !important;
  color: var(--submenu-active-text-color) !important;
}

.menu-item .dropdown-menu .dropdown-item:last-of-type {
  border-bottom: 0 !important;
}

.cart-i-checkout {
  font-size: 24px;
  margin-right: 10px;
}

.cart-i-bag {
  position: relative;
  bottom: 3px;
}

.login-form {
  padding-top: 50px;
}

.acc-from-title {
  font-size: var(--font-lg);
  font-weight: 700;
  text-transform: uppercase;
  line-height: 34px;
  margin-bottom: 20px;
}

.order-number {
  font-size: 16px;
  font-weight: 700;
}

.gpnt-input {
  border: 1px solid #707070 !important;
  border-radius: 0 !important;
  color: #000 !important;
  font-family: inherit !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  height: 48px !important;
  padding: 0 20px !important;
  width: 100% !important;
}

.btn-acc {
  border: 0 none;
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  padding: 0 20px;
  min-height: 50px;
  box-shadow: none;
  text-align: center;
  transition: all 0.2s;
  border-radius: 0;
  position: relative;
}

.min-w-140 {
  min-width: 140px !important;
}

.acc-links {
  margin-bottom: 8px;
}

a {
  color: var(--primary-color);
}

.dd-user-settings .dropdown-toggle,
.dd-user-settings .btn-check:checked+.btn,
.dd-user-settings .btn.active,
.dd-user-settings .btn.show,
.dd-user-settings .btn:first-child:active,
.dd-user-settings :not(.btn-check)+.btn:active {
  background: #ffffff;
  color: #444;
  font-size: var(--font-nano);
  padding-left: 40px;
  box-shadow: 1px 0px 2px -1px #ffffff;
  border: 1px solid #f3f3f3;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
  padding-top: 8px;
  height: 40px;
  max-width: 162px;
  width: 162px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
  padding-right: 24px;
}

.dd-user-settings .dropdown-toggle::before {
  content: "";
  background: url(images/profile.png);
  left: 8px;
  display: inline-block;
  height: 25px;
  width: 25px;
  background-size: 22px;
  background-repeat: no-repeat;
  border-radius: 100%;
  border: 2px solid var(--primary-color);
  position: absolute;
}

.dd-user-settings .dropdown-toggle::after {
  position: absolute;
  font-size: 25px;
  top: 17px;
  right: 8px;
}

.dd-user-settings .dropdown-menu {
  padding: 0;
  margin-top: -2px;
  border: 0;
  padding-top: 5px;
  background: transparent;
}

.dd-user-settings .dropdown-menu .dropdown-item {
  border-bottom: 1px solid #f9f8f8;
  color: var(--submenu-text-color);
  background: var(--submenu-bgcolor);
}

.dd-user-settings .dropdown-menu .dropdown-item:last-of-type {
  border-bottom: 0px solid #f9f8f8 !important;
}

.dd-user-settings .dropdown-menu .dropdown-item:hover,
.menu-item .dropdown-menu .dropdown-item:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.menu-item .dropdown-menu {
  padding-top: 8px;
  border: 0;
  background-color: transparent !important;
  box-shadow: none;
}

.custom-dd-item-container {
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  color: var(--submenu-text-color);
  background: var(--submenu-bgcolor);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  padding: 0;
}

.header-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* text-align: right; */
  padding-right: 8px;
  /* border: solid 2px blue; */
  /* gap:8px; */
}

.promotion-offer-div {
  position: absolute;
  top: 0;
  left: 0;
  background-color: red;
  padding: 2px 5px 2px 5px;
  color: white;
  /* margin: 5px 0 0 -10px; */
  animation: blinker 1s linear infinite;
  z-index: 1;
}

.myacc-menu-item.active {
  color: var(--primary-color);
  font-weight: 700;
  background: transparent;
  border-bottom: 1px solid #eee;
}

.myacc-menu-item {
  font-size: var(--font-xs);
  display: inline-block;
  margin: 0;
  padding: 8px 8px;
  border: 0;
  border-bottom: 1px solid #eee;
  border-radius: 0 !important;
  cursor: pointer;
}

.app-content {
  padding-top: 0px;
  min-height: 100vh;
}

/* .myacc-content,
.acc-content {
  padding-top: 20px;
} */

.orderlist-accordion .accordion-header {
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.orderlist-accordion .accordion-header .accordion-button {
  background: #eee;
}

.myacc-menus {
  box-shadow: 0px 1px 2px 2px #eee;
  padding: 0 20px;
}

hr {
  border-top: 1px solid #ececec;
  /*#ececec; */
}

hr.hr-sep {
  margin: 8px 0px;
}

.header-logo-text {
  color: var(--logo-header-logotext-color);
  line-height: 1.1;
  /* font-size: 36px; */
  /*P*/
  font-size: var(--font-lg);
  /*P*/
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  letter-spacing: -0.9px;
  text-transform: uppercase;
}

.footer-logo-text {
  color: var(--footer-logo-color);
  line-height: 1.1;
  font-size: 36px;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  letter-spacing: -0.9px;
  text-transform: uppercase;
}

.route-loader {
  position: fixed;
  height: 100%;
  width: 100%;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
}

label.error-message {
  color: red;
  margin-top: 5px;
}

.slick-prev:before,
.slick-next:before {
  opacity: 1 !important;
  color: #ececec !important;
}

.dish-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.combo-header {
  background: transparent;
  color: var(--primary-color);
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 20px 5px 20px;
  border: 0;
  border-bottom: 2px solid var(--primary-color);
}

.combo-body {
  padding: 10px 0;
}

.combo-item-card {
  background: transparent;
  border: 0;
  padding: 0 12px 0px 12px;
}


.combo-item-header {
  background: transparent;
  border: 0;
  padding: 0 10px 0 10px;
  text-transform: uppercase;
  font-size: 12px;
}

.pd-variant-rbt {
  margin-bottom: 0;
  /* padding: 5px; */
  /* width: 100%; */
  /* max-width: 35px; */
  /* float: left; */
  /* border: solid 2px red; */
}

.pd-variant-rbt-lbl {
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 5px;
  border-bottom: 2px solid var(--primary-color) !important;
  font-size: var(--font-pico);
  margin-left: 8px;
  /* Old value 10px */
  /* line-height: 20px; */
  /* border: solid 2px blue; */

  display: flex;
  flex-direction: row;
  align-items: center;
}

.vr-price-box {
  /* border: solid 2px lime; */
  margin-left: 8px;
}

.vr-price-box .vr-price.price-srikeout {
  text-align: right;
  font-size: var(--font-pico);
  /* Old value 8px */
}

.vr-price {
  /* margin-left: 5px; */
  margin-bottom: 0;
  padding: 0px 0px 0px 2px;
  /* old value: 4px 2px 2px; */
  font-size: var(--font-nano);
  /* Old value 9px */
  /* position: relative; */
  /* bottom: 2px; */
  /* border-radius: 3px; */
  /* border: 1px solid #efefef !important; */
  /* background: transparent !important; */
  color: #666;
  font-weight: 600;
  /* letter-spacing: 0.5px; */
}

.pd-variant-rbt .form-check-label {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 8px;
}

.pd-variant-rbt .form-check-input {
  position: relative;
  /* bottom: 2px; */
  width: 20px;
  margin: 0;
  padding: 0;
  height: 20px;
}

.secondory-theme-badge {
  color: #fff;
  background-color: var(--secondory-color) !important;
}

.pd-item-name-badge {
  margin-bottom: 0;
  padding: 4px 5px 0 0;
  position: relative;
  bottom: 2px;
  border-radius: 3px;
  border: 0;
  font-weight: 800;
  color: var(--primary-color);
  background: transparent !important;
  font-size: 10px;
  letter-spacing: 0.5px;
}

.pd-item-name-badge::after {
  content: "\f138";
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  margin-left: 5px;
  margin-right: 0px;
}

.pd-item-var-info {
  color: #6c757d;
  margin-left: 5px;
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 20px;
  position: relative;
  font-weight: 600;
  bottom: 1px;
}

input,
textarea {
  border: 1px solid #707070;
  border-radius: 0;
  color: #000;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  /* height: 48px; */

  width: 100%;
}

.payment-method {
  background-color: #f2f0f0;
  border-color: #f2f0f0;
  color: #000;
  display: flex;
  height: 100%;
  padding-left: 12px;
  font-size: 18px;
  min-height: 60px;
  align-items: center;
  cursor: pointer;
}

.chk-payment-method .form-check-input {
  position: relative;
  bottom: 0;
  width: 30px;
  margin: 0;
  padding: 0;
  height: 30px;
}

.lbl-payment-method {
  margin-left: 10px;
  margin-bottom: 0;
}

.payment-right {
  flex: 0 0 460px;
  max-width: 460px;
  word-break: break-word;
  word-wrap: break-word;
  box-shadow: 0px 5px 20px rgb(0 0 0 / 10%);
  border: 0;
  margin-left: auto;
}

.max-w-460 {
  max-width: 460px;
}

.place-order-div {
  padding: 0px 20px 15px;
}

.place-order-btn {
  background-color: var(--primary-color);
  border: 0 none;
  width: 100%;
  min-height: 60px;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  transition: all 0.2s ease;
  display: block;
  text-align: center;
  line-height: 60px;
  text-decoration: none;
}

.promo-title {
  padding: 10px 0;
  font-size: 16px;
  font-weight: 600;
}

.promotion-input {
  border: 1px solid #b6b6b6;
  min-height: 40px;
  font-family: "latosemibold";
  font-size: 14px;
  text-transform: uppercase;
  height: 40px;
  border-radius: 0 !important;
}

.btn-prm-apply {
  position: absolute;
  background-color: var(--secondory-color);
  height: 100%;
  width: 102px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  font-size: 14px !important;
  font-weight: 900;
  text-transform: uppercase;
  transition: all 0.2s ease;
  text-align: center;
  text-decoration: none;
  min-height: 40px;
  height: 40px;
  border-radius: 0 !important;
}

.checkout-img-box {
  width: 100%;
  max-height: 60px;
  padding: 0 5px;
  max-width: 70px;
}

.prod-addon-row {
  padding-bottom: 4px;
}

.prod-addon-title {
  font-weight: 700;
  font-size: 12px;
}

.prod-addon-list {
  margin: 0;
  padding: 0 20px;
  font-size: var(--font-uxs);
}

.prod-addon-list li strong {
  font-weight: 600 !important;
}

.prod-addon-list li {
  margin-bottom: 5px;
}

.addon-price {
  margin-left: 5px;
  display: inline-block;
  background-color: var(--primary-color);
  padding: 0 4px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  font-size: 10px;
}

.payment-right .accordion-item:first-of-type,
.payment-right .accordion-item:first-of-type .accordion-button .payment-right .accordion-item:last-of-type .accordion-button.collapsed,
.payment-right .accordion-item:first-of-type .accordion-button {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.payment-right .accordion-header .accordion-button:focus {
  box-shadow: none !important;
}

.search-box {
  max-width: 469px;
  position: relative;
  margin: auto;
  /* border:solid 2px red; */
}

.search-dropdown-wrap {
  border: 1px solid #e6e6e6;
  position: absolute;
  width: 100%;
  max-height: 400px;
  top: 50px;
  left: 0;
  background-color: #fff;
  z-index: 30;
  border-right: 0;
  box-shadow: 6px 4px 7px -5px rgba(0, 0, 0, 0.3);
  max-width: 469px;
  border-radius: 10px;
}

.search-dropdown-wrap a.close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 22px;
  height: 22px;
  display: inline-block;
  border-radius: 100%;
  padding: 2px;
  line-height: 15px;
  text-align: center;
  opacity: 1;
}

.search-dropdown-wrap .showing-result {
  width: 100%;
  padding: 12px 0 12px 10px;
  margin: 0;
  float: left;
  font-size: 16px;
  font-weight: 600;
  background: #f0f0f0;
  color: #000;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.search-dropdown-wrap .showing-result label {
  padding: 0;
  margin: 0;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
}

.search-dropdown-wrap ul.search-item-suggesion {
  margin: 0;
  padding: 0 0;
  border-right: 1px solid #dedede;
  list-style: none;
  max-height: 300px;
  overflow-y: auto;
}

.search-dropdown-wrap .view-product {
  text-align: center;
  background: #f5f5f5;
  padding: 5px 0;
  border-top: solid #eaeaea 1px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.search-dropdown-wrap .view-product a {
  text-decoration: none;
}

.search-item-suggesion .item-box-card-parent {
  margin-bottom: 0 !important;
  margin-bottom: 5px !important;
}

.search-item-suggesion .item-box-card {
  position: relative;
  z-index: 15;
  box-shadow: none;
}

.search-item-suggesion .item-box-inner {
  flex-direction: row;
  position: relative;
}

.search-item-suggesion .item-img-cont {
  width: 25%;
  height: 80px;
  max-height: 80px;
  object-fit: contain;
}

.search-item-suggesion .item-detail {
  width: 75%;
  padding-bottom: 0;
}

.search-item-suggesion .item-header,
.search-item-suggesion .item-footer {
  padding: 0 var(--font-pico);
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
  border-radius: 0;
}

.search-item-suggesion .item-price {
  font-size: var(--font-xs);
}

.search-item-suggesion .item-cart-btn {
  font-size: var(--font-uxs);
  min-height: calc(2 * var(--font-uxs));
}

.search-item-suggesion .item-box-inner figure .img-org {
  position: relative;
  max-height: 85px;
  object-fit: cover;
  object-position: initial;
  border: 1px solid #f3f3f3;
}

/* address css start */
.address-footer {
  margin-top: auto;
  border-top: 2px solid #f4f4f4;
}

.address-footer-item {
  flex: 1 1 50%;
  padding: 5px 10px;
  text-align: center;
  background: #fff;
  border-right: 2px solid #f4f4f4;
  cursor: pointer;
}

.address-footer-item a {
  text-decoration: none;
}

.address-footer-item:hover,
.address-footer-item:hover {
  color: #fff;
  text-decoration: none;
}

.default-address {
  color: #27537e !important;
  pointer-events: none;
  text-decoration: none;
  background: #eee !important;
  border: 1px solid #d6d6d6;
}

.address-info {
  padding: 10px;
}

.btn-add {
  background-color: var(--btn-findfood-bgcolor);
  color: #fff !important;
  border: 0 none;
  border-radius: 0;
  font-weight: 600;
  text-transform: capitalize;
  padding: 5px 10px;
  -webkit-appearance: none;
  font-size: 12px !important;
  float: right;
}

.w-80x {
  max-width: 80px;
}

.btn-set-default {
  background: #27537e;
  color: #fff;
}

.btn-edit {
  background: var(--secondory-color);
  color: #fff;
}

.btn-danger {
  background-color: #df5f55;
  color: #fff;
}

.chk-default {
  height: 20px !important;
  width: 20px !important;
}

.lbl-chk-default {
  position: relative;
  bottom: 5px;
  left: 5px;
  font-size: 15px;
}

.btn-cancel {
  margin-left: 20px !important;
  background: #fff;
  color: #000;
  border: 1px solid #ccc;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  padding: 0 20px;
  min-height: 50px;
  min-width: 200px;
  border-radius: 25px;
  box-shadow: none;
  text-align: center;
  transition: all 0.2s;
}

.btn-cancel:hover {
  background: #fff !important;
  color: #000 !important;
}

.lnk-choose-address,
.lnk-choose-address i {
  color: #2196f3;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
}

.more-address {
  border: 1px solid #f8f8f8;
  padding: 5px;
  margin-bottom: 10px;
  max-height: 215px;
  overflow-y: auto;
}

.btn-use-this {
  padding: 5px;
  font-size: 10px !important;
  text-transform: capitalize;
  border-radius: 5px;
  line-height: 1.2;
  width: 70px;
}

.li-addrs-list {
  max-width: calc(100% - 85px);
}

.current-adrs {
  background: #4caf50 !important;
}

.lnk-choose-address i svg {
  margin-left: 2px;
  height: 8px;
}

.lnk-choose-address.active i svg {
  transform: rotate(180deg) !important;
}

.lbl-default-address {
  padding: 2px;
  background: #4caf50;
  border-radius: 5px;
  font-size: 11px;
  text-align: center;
  width: 98px;
  color: #fff;
  height: 18px;
  line-height: 15px;
  font-weight: 600;
}

/* address css end */

.checkout-items-info .accordion-body {
  padding: 0px;
}

.accordion-item:last-of-type {
  border-radius: 0 !important;
}

.checkout-items-info .accordion-button {
  padding: 8px;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.checkout-items-info .prod-addon-container {
  padding: 4px 8px;
  border-radius: 0 !important;
}

.checkout-items-info .accordion-item {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.no-optset {
  pointer-events: none;
}

.no-optset .accordion-button::after {
  background-image: none;
  pointer-events: none;
}

.checkout-sub-item .accordion-button:not(.collapsed) {
  background-color: #fff;
  color: #000;
  box-shadow: none;
}

.checkout-order-info-title {
  font-size: 18px;
  border-bottom: 2px solid #f3f3f3;
  color: var(--primary-color);
  font-weight: 700;
  padding: 15px 10px !important;
}

.sw-sec-slider-item {
  position: relative;
  width: 100%;
  display: inline-block;
}

.sw-sec-item-wrap {
  width: 290px;
  padding: 0 7px;
}

.sw-sec-slider-image {
  margin: 0;
  position: relative;
  padding-bottom: 40%;
  border-radius: 10px;
  overflow: hidden;
}

.sw-sec-slider-item img {
  height: auto;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  object-fit: cover;
  /* border-radius: 10px; */
}

.sw-sec-slider-container .slick-prev,
.sw-sec-slider-container .slick-next {
  z-index: 1;
}

.sw-sec-slider-container .slick-prev {
  left: 20px;
}

.sw-sec-slider-container .slick-next {
  right: 20px;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: var(--primary-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.max-w-469 {
  max-width: 469px;
}

.header-placeholder {
  height: 110px;
  margin-bottom: 15px;
}

.menubar-placeholder {
  height: 52px;
  margin-top: 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background: #ccc;
}

.services-placeholder {
  height: 80px;
  margin-top: 0;
  border-top: 1px solid #eee;
}

.h-199 {
  height: 199px;
}

.h-299 {
  height: 299px;
}

.logo-img-placeholder {
  height: 116px;
  border-radius: 100%;
  width: 116px;
}

.acc-menu-placeholder {
  width: 205px !important;
  margin-left: auto;
}

.find-btn-placeholder {
  width: 120px;
  border-left: 1px solid #eee;
  height: 50px;
  float: right;
}

.shimmerBG {
  animation-duration: 3.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;
}

.placeholder-img {
  width: 100%;
  height: auto;
  border: 1px solid #ccc;
  background: #ccc;
}

.content-line-placeholder {
  height: 50px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
}

.i-modal-close {
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 1rem;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}

.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}

.icon-style {
  background-color: var(--primary-color);
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #fff;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(20px);
  }

  50% {
    transform: translateY(0px);
  }

  75% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.min-h-54 {
  min-height: 54px;
}

.pd-top-price {
  /* border-bottom: 2px solid var(--primary-color); */
  /* padding-bottom: 5px; */
  /* border: solid 2px blue; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.pd-top-price .price-srikeout {
  margin-right: 5px;
}

.ci-variant-name {
  font-size: 9px;
  font-weight: 800;
  text-align: center;
  margin: auto;
  margin-top: 2px;
  color: var(--primary-color);
  flex: 1 1 100%;
}

.slot-close {
  background: #eee;
  color: #000;
  pointer-events: none;
  cursor: not-allowed;
}

.slot-close td {
  color: red !important;
}

.price-srikeout {
  text-decoration: line-through;
  /* margin-right: 5px; */
  font-size: 12px;
  padding-right: 4px;
}

.promocode-loader {
  height: 16px;
  width: 16px;
  margin-right: 5px;
}

.promotion-input-applied {
  border: 1px solid #b6b6b6;
  min-height: 40px;
  font-family: "latosemibold";
  font-size: 14px !important;
  color: #008000 !important;
  height: 40px;
  padding-left: 5px !important;
}

.btn-remove-promocode {
  background-color: var(--secondory-color) !important;

  color: #fff !important;
}

.price-srikeout-cart {
  text-decoration: line-through;
  margin-right: 5px;
  font-size: 10px;
  /* position: absolute; */
  /* top: 8px; */
}

.pd-combo-item input:checked:disabled {
  opacity: 1 !important;
}

.btn-loader {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 13px;
  right: 15px;
  max-width: 20px;
  float: left;
}

.Toastify__toast-container {
  /* top: 0;
	left: 0;
	transform: none;
	width: 100%;
	border-radius: 0; */
  padding: 0;
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
}

/* .Toastify__toast {
	border-radius: 0;
} */

.Toastify__close-button>svg {
  fill: currentColor;
  height: 28px;
  width: 26px;
  position: relative;
  top: 10px;
}

.otp-sending {
  pointer-events: none;
  text-decoration: underline;
  cursor: not-allowed;
  color: #a5a5a5;
  font-weight: 500;
}

.single-adv .slick-track {
  justify-content: center;
}

.clear-search {
  position: absolute;
  left: -20px;
  z-index: 1500;
  color: var(--bs-danger);
  top: 15px;
}

.view-all-search {
  border: 0;
  font-size: 12px;
  color: var(--primary-color);
  text-decoration: underline;
  font-weight: 600;
}

.tag-badge {
  font-weight: 800;
  font-size: 9px;
  border: 1px solid #d5d5d5;
  text-transform: uppercase;
}

.cart-remove {
  color: var(--bs-danger);
}

.accordion-button:focus {
  box-shadow: none !important;
}

.no-acc-body .accordion-button:focus {
  color: inherit !important;
  background-color: inherit !important;
}

.no-acc-body .accordion-button::after {
  width: 0;
  height: 0;
}

.myorder-div {
  background-color: #fff;
}

.myorder-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.myorder-paydetail {
  /* flex: 0 0 35%; */
}

.myorder-paydetail ul li span {
  font-weight: 700;
}

.myorder-div {
  padding: 10px;
  box-shadow: 0px 5px 20px rgb(0 0 0 / 10%);
  margin-bottom: 20px;
}

.placed-header {
  background: #3f51b5 !important;
  border-color: #3f51b5 !important;
}

.confirmed-header {
  background: #8bc34a !important;
  border-color: #8bc34a !important;
}

.ready-header {
  background: #3195a5 !important;
  border-color: #3195a5 !important;
}

.completed-header {
  background: #3e884f !important;
  border-color: #3e884f !important;
}

.cancelled-header {
  background: #c43d4b !important;
  border-color: #c43d4b !important;
}

.refunded-header {
  background: #3e884f !important;
  border-color: #3e884f !important;
}

.ontheway-header {
  background: #ffc107 !important;
  border-color: #ffc107 !important;
}

.un-paid-header {
  background: #767676 !important;
  border-color: #767676 !important;
}

.repeat-order-header {
  background: #6bb937 !important;
  border-color: #6bb937 !important;
}

.btn-myacc {
  min-width: 100px;
  margin-top: 10px;
}

.order-details {
  padding: 0;
  background: #f8f9fa;
  margin-top: 20px;
  border: 1px solid #eee;
}

.gpnt-item-sep {
  border-top: 2px solid #ccc;
  margin: 0;
}

.od-item-header {
  padding: 4px;
  border-bottom: 1px solid #e9e9e9;
  background-color: #eee;
  font-weight: 600;
  font-size: var(--font-nano);
}

.od-sets {}

.od-addon-list {
  margin: 0;
  padding-left: 25px;
}

.od-sub-items {
  margin-bottom: 8px;
  border: 1px solid #eee;
}

.od-item-price {
  font-weight: 700;
  font-size: var(--font-nano);
}

.od-addon-row {
  padding-bottom: 0;
}

.show-modal {
  display: block;
}

.footer-contact,
.footer-contact:hover {
  font-size: var(--font-sm);
  color: var(--footer-text-color);
  margin-right: 8px;
  text-decoration: none;
}

.web-content-header {
  border-bottom: 1px solid #eee !important;
  margin-bottom: 0;
}

.btn-tag-filter {
  padding: 2px 4px;
  font-size: var(--font-pico);
  border-radius: 3px;
  text-transform: uppercase;
}

.active-tag {
  font-weight: 800;
}

.bg-grey {
  background-color: #eee !important;
}

.cookies {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 25px;
  font-family: inherit;
  bottom: 0px;
  position: fixed;
  display: block;
  width: 100%;
  text-align: center;
  -webkit-box-shadow: -1px -2px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -1px -2px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: -1px -2px 5px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  font-size: var(--font-uxs);
  text-transform: uppercase;
}

.cookie-link {
  color: var(--bs-blue);
  font-weight: 600;
  margin: 0 14px;
  text-decoration: none;
  font-size: var(--font-uxs);
  text-transform: uppercase;
}

.cookies a.cli_settings_button {
  color: #158802;
  padding: 0px 20px 0 20px;
  margin: 0px;
  text-decoration: none;
  outline: 0px;
  font-weight: 600;
}

.cookies a.cli_settings {
  color: #fff;
  padding: 0px;
  margin: 0px;
  display: inline-block;
  width: 100px;
  background: #000;
  height: 35px;
  line-height: 35px;
  outline: 0px;
  text-decoration: none;
}

.modal-fullscreen-cookies-popup .footer-modal-content {
  font-size: 14px;
  border: solid #dadada 1px;
  border-radius: 6px;
  padding: 10px;
  color: #b2b2b2;
}

.cat-desc {
  font-size: 14px;
  text-transform: capitalize;
  margin: 0;
  color: #919191;
}

.sidebar-menu {
  overflow-y: auto;
  overflow-x: hidden;
}

.cat-max-w {
  max-width: 90px;
}

.res-offline-msg {
  height: 50px;
  /* position: fixed; */
  z-index: 1500;
  width: 100%;
  line-height: 50px;
  font-size: var(--font-uxs);
}

.res-offline-msg-dummy {
  height: 50px;
}

.menu-item.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

/* width */
.sidebar-menu::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.sidebar-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.sidebar-menu::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.sidebar-menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sw-sec-slider {
  margin-top: 10px;
  margin-bottom: 10px;
}

.item-img,
.cat-img {
  max-width: 100%;
  height: auto;
}

.res-open-hours-header {
  font-weight: 500;
  font-size: var(--font-lg);
  line-height: 21px;
  padding: 1rem 0;
  text-align: left;
}

.tbl-open-hours td {
  padding: 4px;
  font-size: var(--font-footer);
  border-bottom: 1px solid #545454;
}

.tbl-open-hours {
  color: var(--footer-text-color);
  max-width: 340px;
}

.tbl-open-shours {
  max-width: 340px !important;
}

.res-active-day {
  max-width: 340px !important;
  color: var(--footer-logo-color);
  font-weight: 600;
  font-size: var(--font-micro);
}

.ot-time-sep {
  display: block;
  text-align: right;
}

.oh-time-td {
  /* border-bottom: 1px solid #eee!important; */
  padding: 0 5px;
}

.oh-time-td .ot-time-sep:last-of-type::after {
  content: "";
}

.day-close {
  display: block;
  text-align: right;
}

.day-24h {
  display: block;
  text-align: right;
}

.field-error {
  border: 1px solid #e50016;
}

.dinin-info {
  background-color: transparent !important;
  margin: 0 !important;
  font-weight: 600;
  color: var(--chooser-services-text-color) !important;
  font-size: var(--font-pico);
  max-width: 140px;
  min-width: 140px;
  border: 1px solid #e9e9e9 !important;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;
}

/* responsive css start */
@media (min-width: 600px) {

  .sw-slick-slider .slick-prev,
  .sw-slick-slider .slick-next {
    width: 30px;
    height: 30px;
  }

  .sw-sec-item-wrap {
    width: 660px;
    padding: 0 10px;
  }

  .sw-slick-slider .slick-prev:before,
  .sw-slick-slider .slick-next:before {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  .menu-item.dropdown:hover .dropdown-menu {
    display: block;
  }

  .sw-sec-slider-container .slick-prev {
    left: 20px;
  }

  .sw-sec-item-wrap {
    width: 1150px;
    padding: 0 15px;
  }

  .sw-sec-slider-image img {
    border-radius: 10px;
  }
}

@media (max-width: 1199.98px) {
  .header-logo-text {
    /* font-size: 1.6em; */
    /*P*/
    /* padding-left: 48px; */
    /*P*/
  }

  .slick-prev {
    left: -20px;
  }

  .slick-next {
    right: -20px;
  }

  .footer-logo {
    font-size: 25px;
  }

  .menu-item {
    padding: 0 12px;
    padding-bottom: 0;
    letter-spacing: 0.5px;
    font-size: 14px !important;
    margin: 0;
    border-bottom: 1px solid #f4f4f4;
  }

  .menu-item.active {
    background: var(--primary-color);
    color: #fff !important;
  }

  .menu-item.active:after,
  .menu-item:hover:after {
    height: 0;
  }

  .menu-item .custom-dd-item-container {
    width: 100%;
    max-width: 100%;
    padding: 0;
    position: relative;
    border-radius: 0;
    color: var(--submenu-text-color);
    background: var(--submenu-bgcolor);
    margin: 0;
  }

  .menu-item .dropdown-menu {
    position: relative;
    padding-top: 0;
  }

  .menu-item .dropdown-menu .dropdown-item {
    padding: 8px 8px;
    font-size: 16px;
    border-bottom: 1px solid #ffffff;
    font-weight: 600;
  }

  .app-menubar .navbar-toggler {
    padding: 8px 8px;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  /* .logo-img-box {
     text-align: center;
    margin-left: 20px;
    max-width: 100px;
    margin-right: 20px;
  } */

  .contact-detail {
    font-size: 1rem;
  }

  .adv-info-box-main {
    min-height: auto;
    padding: 0 15px;
  }
}

@media (min-width: 768px) {
  .sw-sec-slider-image {
    padding-bottom: 26.3%;
  }

  .cart-item-name,
  .checkout-qty,
  .cart-item-price,
  .cart-service-label,
  .cart-service-value,
  .cart-service-label,
  .cart-service-price {
    font-size: var(--font-uxs) !important;
  }
}

@media (max-width: 1023.98px) {
  .payment-right {
    max-width: 100%;
  }

  /* .menu-item.active,
  .menu-item.active .dropdown-toggle.nav-link {
    display: flex;
  } */

  .sw-sec-slider-image {
    padding-bottom: 26.3%;
  }

  /* .menu-item {
    padding: 0 12px;
    padding-bottom: 0;
    letter-spacing: 0.5px;
    font-size: 0.8rem !important;
    margin: 0;
    border-bottom: 1px solid #f4f4f4;
  }

  .menu-item.active {
    background: var(--primary-color);
    color: #fff !important;

  }

  .menu-item.active:after,
  .menu-item:hover:after {
    height: 0;
  }

  .menu-item .custom-dd-item-container {
    width: 100%;
    max-width: 100%;
    padding: 0;
    position: relative;
    border-radius: 0;
    color: var(--submenu-text-color);
    background: var(--submenu-bgcolor);
    margin: 0;
  }

  .menu-item .dropdown-menu {
    position: relative;
    padding-top: 0;
  }


  .menu-item .dropdown-toggle::after {
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    border: 0;
    position: relative;
    left: 5px;
    float: right;

  }

  .menu-item .dropdown-toggle[aria-expanded="false"]::after {
   
    content: "\f13a";
  }

  .menu-item .dropdown-toggle[aria-expanded="true"]::after {
    content: "\f13a";
  }

  .menu-item .dropdown-menu .dropdown-item {
    padding: 8px 8px;
    font-size: 16px;
    border-bottom: 1px solid #ffffff;
    font-weight: 600;
  } */

  .app-menubar .navbar-toggler {
    padding: 8px 8px;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .dd-user-settings {
    position: relative;
    top: 0px;
  }
}

@media (max-width: 667.98px) {
  .sw-sec-slider {
    margin-top: 0px;
    margin-bottom: 6px;
  }

  /* .search-container {
    margin-left: 0px;
    margin-top: 5px;
    padding: 10px;
    border-top: 1px solid #fff;
  } */
  .sticky-top {
    padding-top: 140px !important;
  }

  .logo-img-box {
    /* padding: 6px 0px 6px 0 !important; */
    /*P*/
    height: 60px;
    max-height: 100%;
    /* text-align: right; */
    /*P*/
    /*margin-left: 60px !important;*/
    /*P*/
    width: 80px;
    padding-left: 0px;
    /* Old value: 85px */
    /*P*/
    /*margin-right: 0 !important;*/
    /*P*/
  }

  .logo-img-box.no-menus {
    margin-left: 20px !important;
  }

  .cart-icon {
    /* margin-right: 15px; */
  }

  /* .cart-addedcount {
    top: -15px;
    right: 15px;
  } */

  .contact-detail,
  .footer-logo {
    text-align: left !important;
    justify-content: flex-start;
  }

  .contact-detail img {
    margin: 0;
  }

  .modal-dialog {
    margin: 0;
    min-height: 100%;
    height: 100%;
  }

  .modal-content {
    border-radius: 0;
    /*max-height: 100%;
    overflow: hidden;
    min-height: 100vh;*/
  }

  .confirm-box-body {
    display: flex;
    align-items: flex-start;
    font-size: 24px;
  }

  .choose-service-section {
    position: relative;
  }

  .choose-service-stack {
    /* flex-direction: column; */
    /* align-items: flex-start; */
    /* gap: 0rem !important; */
    /*margin-top: 8px;*/
    /*P*/
    /*margin-bottom: 7px;*/
    /*P*/
    /* padding: 15px; */
  }

  .choose-title {
    /*margin-top: 15px;*/
    /*P*/
  }

  /* .btn-restart {
    position: absolute;
    top: 15px;
    right: 18px;
  } */

  .service-radios {
    padding-left: 24px;
  }

  .service-radios .form-check-input {
    width: calc(var(--font-xs) * 2);
    height: calc(var(--font-xs) * 2);
  }

  .service-radios .form-check-label {
    /* font-size: 18px; */
  }

  .choose-service-text {
    /* padding-bottom: 5px;  */
  }

  .adv-info-box h2 {
    font-size: 18px;
    margin-top: 10px;
  }

  .footer-bottom .text-end {
    text-align: left !important;
  }

  .item-box-inner {
    flex-direction: unset;
  }

  .item-img-cont {
    flex: 1 1 25%;
    max-width: 25%;
    height: 100%;
    max-height: 100%;
  }

  .item-box-inner figure {
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .item-box-inner figure .img-org {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    background: #fff;
    object-fit: contain;
    padding: 0;
    object-position: center;
    padding-right: 4px;
    padding-left: 4px;
    box-shadow: 1px 1px 2px -2px #dbd7d7;
  }

  img.img-placeholder {
    height: 100%;
    max-height: 100%;
    object-fit: contain;
    max-width: 100%;
  }

  .item-detail {
    color: #000;
    font-family: "latosemibold";
    font-weight: normal;
    font-size: 14px;
    /* padding: 5px 0; */
    display: flex;
    flex-direction: column;
    flex: 1 1 75%;
    max-width: 75%;
    padding-bottom: 0;
  }

  .menu-item-title {
    font-size: var(--font-md);
    /* font-size: 1rem; */
    /* margin-top: 0; */
  }

  .item-title {
    text-transform: capitalize;
    font-size: var(--font-sm);
    font-weight: 900;
    line-height: 1.5;
    padding: 0 8px;
  }

  .item-qty {
    font-size: 12px;
    line-height: 12px;
    /* margin-top: 5px; */
    margin-bottom: 0;
    font-family: "Lato", sans-serif;
    text-align: initial;
    text-transform: capitalize;
  }

  .dish-desc {
    margin-bottom: 0;
    line-height: 1.3;
    padding: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .item-box-inner figure {
    overflow: visible;
  }

  .item-notavailabletime {
    padding: 0 8px;
  }

  .item-price-cart {
    padding: 10px 8px 10px;
    border-top: 1px solid #f9f9f9;
    /*#f9f9f9;*/
    /* margin-top: 10px; */
  }

  .item-cart-btn {
    border-radius: 8px;
    min-height: 30px;
    font-size: 16px;
    padding: 0 10px;
    position: relative;
    top: 2px;
    right: 0;
    width: 75px;
  }

  .item-price {
    font-size: var(--font-uxs);
  }

  .add-on-name {
    padding-left: 4px;
    font-size: var(--font-xs);
  }

  .pd-variant-rbt-lbl {

    font-size: var(--font-micro);

  }

  .add-on-checkbox {
    font-size: var(--font-xs);
    text-align: left;
    line-height: calc(var(--font-xs) * 2);
  }

  .add-on-checkbox .form-check-input,
  .pd-combo-item-chk .form-check-input {
    height: calc(var(--font-xs) * 1.4);
    width: calc(var(--font-xs) * 1.4);
  }

  .form-check-input:focus {
    box-shadow: none !important;
  }

  .menu-item {
    padding: 0 12px;
    padding-bottom: 0;
    letter-spacing: 0.5px;
    margin: 0;
    border-bottom: 1px solid #f4f4f4;
    font-size: 0.8rem !important;
    justify-content: flex-start !important;
    align-items: baseline;
    flex-direction: column;
    width: 100%;
  }

  .menu-item.show {
    padding: 0 !important;
  }

  .menu-item.dropdown .dropdown-toggle {
    /* display: flex;
    width: 100%; */
    /* flex-direction: row; */
    /* justify-content: flex-end; */
  }

  .menu-item.dropdown.show .dropdown-toggle {
    padding-left: 13px;
  }

  .menu-type::before {
    display: none;
  }

  .menu-item.menu-type {
    padding: 0;
    display: flex;
    flex-direction: column;
    line-height: 55px;
  }

  .menu-type .nav-link {
    font-size: 22px;
    padding: 0;
  }

  .menu-item.active {
    background: var(--primary-color);
    color: #fff !important;
  }

  .menu-item.active:after,
  .menu-item:hover:after {
    height: 0;
  }

  .menu-item .custom-dd-item-container {
    width: 100%;
    max-width: 100%;
    padding: 0;
    position: relative;
    border-radius: 0;
    color: var(--submenu-text-color);
    background: var(--submenu-bgcolor);
    margin: 0;
  }

  .menu-item .dropdown-menu {
    position: relative;
    padding-top: 0;
  }

  .menu-item .dropdown-toggle::after {
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    border: 0;
    position: absolute;
    right: 10px;
    text-align: right;
  }

  .menu-item:not(.menu-type).active,
  .menu-item:not(.menu-type).active .dropdown-toggle.nav-link {
    width: 100%;
    max-width: 100%;
    padding: 0;
    position: relative;
    border-radius: 0;
    /* color: #fff;
    background: var(--nav-active-text-color); */
    margin: 0;
    flex-direction: column;
    font-size: 0.8rem;
  }

  .menu-item:not(.menu-type).active:after,
  .menu-item:not(.menu-type):hover:after {
    height: 0 !important;
  }

  .menu-item:not(.dropdown).active {
    padding-left: 13px !important;
  }

  .menu-item.active.nav-item .dropdown-toggle.nav-link {
    padding: 0 !important;
    padding-left: 13px !important;
  }

  .menu-item .dropdown-toggle[aria-expanded="false"]::after {
    content: "\f138" !important;
    /* width: 20px; */
    /* border: solid 2px blue; */
  }

  .menu-item.menu-type .dropdown-toggle[aria-expanded="false"]::after {
    /* font-size: 28px; */
  }

  .menu-item .dropdown-toggle[aria-expanded="true"]::after {
    content: "\f13a" !important;
    /* width: 20px; */
    /* border: solid 2px red; */
  }

  .menu-item .dropdown-menu .dropdown-item {
    padding: 8px 8px 8px 13px;
    font-size: 12px;
    border-bottom: 1px solid #ffffff;
    font-weight: 600;
  }

  .menu-item .dropdown-menu.show {
    position: initial !important;
    padding-top: 0;
    width: 100%;
    margin-bottom: 0;
  }

  .app-menubar .navbar-toggler.collapsed {
    padding: 0;
    background-color: transparent;
    position: absolute;
    top: 15px;
    left: 14px;
    z-index: 1500000;
    border: 0;
    color: var(--primary-color);
    font-size: 22px;
  }

  .app-menubar .navbar-toggler {
    padding: 0;
    background-color: transparent;
    position: absolute;
    top: 8px;
    left: 5px;
    z-index: 1500000;
    border: 0;
    color: var(--primary-color);
    font-size: 18px;
  }

  .app-menubar .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
    background-image: none !important;
  }

  .app-menubar .navbar-toggler:not(.collapsed) .navbar-toggler-icon::after {
    font-family: "Font Awesome 5 Free";
    content: "\f359";
    font-weight: 900;
    top: 10px;
    position: absolute;
    font-size: 30px;
    color: var(--primary-color);
    left: 5px;
  }

  .app-header {
    box-shadow: 0 3px 25px rgba(0, 0, 0, 0.16);
    padding: 0;
    position: fixed;
    width: 100%;
    /* height: 145px; */
    top: 0;
    z-index: 5;
  }

  .res-offline.app-header {
    top: 40px !important;
    z-index: 1500;
  }

  .res-offline-msg {
    position: fixed;
    line-height: 40px;
  }

  .main-container {
    padding-top: 95px;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .cart-popover {
    width: 100%;
    position: fixed !important;
    left: 0 !important;
    min-width: 100%;
    transform: translate3d(0, 0, 0) !important;
    min-height: 100vh;
    top: 0 !important;
    margin-top: 0;
  }

  .cart-item-qty-box {
    margin: 5px;
  }

  .orderlist-header-stack {
    flex-direction: column;
    align-items: start;
  }

  .app-content {
    /* padding-top: 20px; */
    /* Old value 50px */
  }

  .sw-sec-slider-image {
    padding-bottom: 40%;
  }

  .adv-desc {
    font-size: 0.8rem;
  }

  .adv-info-box {
    line-height: 1.5;
    min-height: 130px;
  }

  .adv-info-box-main {
    display: flex;
    align-items: center;
    padding: 0 0;
    min-height: auto;
  }
}

.pac-container,
.pac-item {
  z-index: 1150;
}

/* .sticky-top {
  top: 140px;
} */

/* responsive css end */

/* Revised CSS Responsive Code written by P*/

.toggle-sidemenu-canvas {
  /* position: relative; */
  z-index: 100;
  /* left: 15px; */
  /* top: 14px; */
  font-size: 34px;
  color: var(--primary-color);
  margin-left: 15px;
}

.offcanvas-logo-img-box {
  padding: 0 !important;
  height: 30px;
  max-height: 100%;
  text-align: right;
  margin-left: 0px !important;
  max-width: 100%;
  margin-right: 0 !important;
}

.offcanvas-img-logo {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  min-height: 50px;
  object-position: center;
  position: relative;
  bottom: 5px;
}

.sidemenu-offcanvas {
  max-width: 260px;
}

.sidemenu-offcanvas .offcanvas-body {
  padding: 0 !important;
  background-color: var(--navbg-color);
}

.sidemenu-offcanvas .btn-close {
  font-size: 22px;
}

.navbar-expand-md .navbar-collapse.collapse.show {
  display: flex !important;
  flex-basis: auto;
}

.menu-item.active,
.menu-item.active .dropdown-toggle.nav-link {
  display: flex;
}

.menu-item {
  padding: 0 12px;
  padding-bottom: 0;
  letter-spacing: 0.5px;
  font-size: 0.8rem !important;
  margin: 0;
  border-bottom: 1px solid #f4f4f4;
  text-transform: capitalize;
  /* border: solid 2px lime; */
}

.menu-item.active {
  background: var(--primary-color);
  /* color: #fff !important; */
}

.menu-item.active:after,
.menu-item:hover:after {
  height: 0;
}

.menu-item .custom-dd-item-container {
  width: 100%;
  max-width: 100%;
  padding: 0;
  position: relative;
  border-radius: 0;
  color: var(--submenu-text-color);
  background: var(--submenu-bgcolor);
  margin: 0;
}

.menu-item .dropdown-menu {
  position: relative;
  padding-top: 0;
}

.menu-item .dropdown-toggle::after {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  border: 0;
  /* position: relative; */
  /*P*/
  /* left: 5px; */
  /*P*/
  /* float: right; */
  /*P*/
}

.menu-item .dropdown-toggle[aria-expanded="false"]::after {
  /* content: "\f138"; */
  content: "\f13a";
}

.menu-item .dropdown-toggle[aria-expanded="true"]::after {
  content: "\f13a";
}

.menu-item .dropdown-menu .dropdown-item {
  padding: 8px 8px;
  font-size: 16px;
  border-bottom: 1px solid #ffffff;
  font-weight: 600;
}

.app-menubar .navbar-nav {
  /* flex-wrap: initial; */
  width: 100%;
}

.footer-logo img {
  object-position: left center;
}

.cart-control {
  position: relative;
  /* border: solid 2px red; */
}

.btn-restart-box {
  display: flex;
  justify-content: flex-end;
}

.choose-service-section-box {
  padding: 8px;
  margin-bottom: 6px;
}

.choose-service-stack {
  /* border: solid 2px blue; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 0px;
  /* Old value 8px 0px */
}

.choose-service-stack .service-radios {
  width: 50%;
  /* border: solid 2px red; */
}

.cart-item-note {
  font-size: var(--font-uxs);
  color: #000;
}

.modal-header {
  border-bottom: none;
}

.menu-item.dropdown .dropdown-toggle {
  display: flex;
  width: 100%;
  /* flex-direction: row; */
  /* justify-content: flex-end; */
}

.modal-header.product-detail-modal-header {
  display: block;
  padding: 0px;
  position: absolute;
  width: 100%;
  z-index: 100;
  background: transparent;

}

.product-details-header-name {
  opacity: 0;
}

.show-header {
  box-shadow: 1px 1px 1px #ebebeb;
  background: #ffffff !important;
}

.show-header .product-details-header-name {
  opacity: 1 !important;
}

.modal-header .product-detail-modal-popupclose {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1000;
  font-size: var(--font-sm);
  line-height: var(--font-sm);
}

.modal-body.product-detail-modal-body {
  padding-top: 0px;
  padding-bottom: 0px;
  padding: 0;
}

.modal-footer.product-detail-modal-footer {
  padding-top: 8px;
  border-top: 1px solid #eee;
}

.product-detail-name {
  color: var(--primary-color);
  font-size: 20px;
  line-height: 18px;
  font-weight: 900;
}

.pd-variants {
  padding: 0 20px;
}

.product-detail-desc {
  /* margin-bottom: 8px; */
  padding: 5px 2px 5px 20px;
  ;
}

.product-detail-desc p {
  margin-bottom: 4px;
}

.product-detail-desc p:last-child {
  margin-bottom: 0px;
}

.product-detail-strike-box {
  display: flex;
  justify-content: flex-end;
  padding: 0px;
}

.product-detail-strike-price-sm {
  font-weight: 800;
  font-size: var(--font-pico);
}

.product-detail-strike-price-lg {
  font-weight: 800;
  font-size: var(--font-micro);
}

.dc-slt-itm-price {
  margin-left: 4px;
}

.item-img-cont {
  /* border: solid 1px red; */
}

.cart-item-box {
  /* border: solid 1px blue; */
  /* border: solid 1px lightgray; */
  /* border-radius: 3px; */
  border-top: solid 1px lightgray;
  padding: 4px 1px;
  /* margin-bottom: 8px; */
}

.cart-item-box:last-child {
  border-bottom: solid 1px lightgray;
  margin-bottom: 8px;
}

.cart-service-label {
  font-weight: 700;
  font-size: var(--font-sm);
}

.cart-service-value {
  font-weight: 600;
  font-size: 14px;
  font-size: var(--font-sm);
}

.cart-service-price {
  font-weight: 800;
  font-size: var(--font-sm);
}

.now-sym {
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sec-sidebar-menu {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.lbl-payment-surcharge-info {
  font-size: 12px;
  color: #777;
  font-weight: 600;
}

/* Style the scrollbar */
.cart-popover-body::-webkit-scrollbar {
  width: 6px;  /* Horizontal scrollbar width */
  height: 6px; /* Vertical scrollbar height */
}

/* Scrollbar track */
.cart-popover-body::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 2px;
}

/* Scrollbar thumb */
.cart-popover-body::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, #c7c7c7, #cccac8); /* Gradient color */
  border-radius: 2px;
  transition: background 0.3s ease;
}

/* Thumb hover effect */
.cart-popover-body::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(135deg, #c7c7c7, #cccac8); /* Gradient color */
}

@media (min-width: 576px) {}

@media (min-width: 768px) {
  .footer-logo img {
    object-position: right center;
  }

  .choose-service-stack .service-radios {
    width: auto;
    margin-right: 12px;
  }

  .choose-service-stack .service-radios:last-child {
    margin-right: 0px;
  }
}

@media (min-width: 992px) {}

@media (min-width: 1200px) {
  .choose-service-stack {
    padding: 0px 8px;
  }
}

@media (min-width: 1400px) {}